@font-face {
  font-family: "Quicksand";
  src: local("Quicksand"), url(./fonts/Quicksand-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "TaipeiSansTCBeta";
  src: local("TaipeiSansTCBeta"),
    url(./fonts/TaipeiSansTCBeta-Regular.ttf) format("truetype");
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  text-transform: uppercase;
  background-color: #1e163d;
  color: white;
  /* hacky solution to scrollbar showing on mission select page */
  overflow: var(--overflow);
  scrollbar-gutter: stable;
  width: 100%;
}

#root,
.App {
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  box-sizing: border-box;
  font-family: "Quicksand", "TaipeiSansTCBeta", -apple-system,
    BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-weight: bold;
}

button {
  border: none;
  background: none;
  color: white;
}

button:not(.disabled) {
  cursor: pointer;
}
button.disabled {
  opacity: 0.7;
}

button:hover:not(.disabled):not(.game-btn) {
  opacity: 0.5;
}
button:active:not(.disabled):not(.game-btn) > * {
  transform: scale(0.9);
}

input {
  background-color: #3b2378;
  font-size: 1.5rem;
  padding-block: 0.5rem;
  border: 0;
  border-radius: 2rem;
  padding-left: 1rem;
  padding-right: 1rem;

  color: white;
}

input:focus {
  outline: none;
}

input::placeholder {
  color: rgba(255, 255, 255, 0.7);
}
input::-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.7);
}

input:disabled {
  opacity: 0.5;
}

@keyframes shake {
  0% {
    transform: translate(0.1px, 0.1px) rotate(0deg);
  }
  10% {
    transform: translate(-0.1px, -0.2px) rotate(-0.1deg);
  }
  20% {
    transform: translate(-0.3px, 0px) rotate(0.1deg);
  }
  30% {
    transform: translate(0.3px, 0.2px) rotate(0deg);
  }
  40% {
    transform: translate(0.1px, -0.1px) rotate(0.1deg);
  }
  50% {
    transform: translate(-0.1px, 0.2px) rotate(-0.1deg);
  }
  60% {
    transform: translate(-0.3px, 0.1px) rotate(0deg);
  }
  70% {
    transform: translate(0.3px, 0.1px) rotate(-0.1deg);
  }
  80% {
    transform: translate(-0.1px, -0.1px) rotate(0.1deg);
  }
  90% {
    transform: translate(0.1px, 0.2px) rotate(0deg);
  }
  100% {
    transform: translate(0.1px, -0.2px) rotate(-0.1deg);
  }
}
